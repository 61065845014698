const NAME_REGEX = /^(?!.* {2})(?=\S)(?=.*\S$)[a-zA-Z ]+$/
const NAME_SPACES_REGEX = /\b[A-Z][a-z](?!.* {2})[ \w.-]* [A-Z][a-z](?!.* {2})[ \w.-]*( [A-Z])?\b/
const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/
const COMPANY_REGEX = /^(?!.* {2})(?=\S)(?=.*\S$)[a-zA-Z0-9 &]+$/
const DOMAIN_REGEX = /^(?!.* {2})(?=\S)(?=.*\S$)[a-z0-9 -]+$/
const DOMAIN_SPACES_REGEX = /^[a-z0-9-]*$/

export {
  NAME_REGEX,
  NAME_SPACES_REGEX,
  EMAIL_REGEX,
  COMPANY_REGEX,
  DOMAIN_REGEX,
  DOMAIN_SPACES_REGEX,
}
